import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const DashboardModulev2 = new Module({
  id: 'dashboardv2',
  name: 'Dashboardv2',
  label: 'Dashboard v2',
  path: '/dashboardv2',
  isNew: true,
  Component: ComponentLoader(lazy(() => import('src/content/Dashboardv2')))
});

export default new ModuleBundle('Admin Dashboard', [DashboardModulev2]);
