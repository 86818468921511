/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled
} from '@mui/material';

import ShortcutIcon from '@mui/icons-material/Shortcut';
import NAV_SHORTCUTS from 'src/config/shortcuts.config';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
          width: ${theme.spacing(7)};
          height: ${theme.spacing(7)};
  `
);

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

function ShortcutOptions() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  //   useEffect(() => {}, []);

  return (
    <>
      <Tooltip arrow title="View Shortcuts">
        <IconButtonWrapper color="success" onClick={openModal}>
          <ShortcutIcon />
        </IconButtonWrapper>
      </Tooltip>
      <Dialog maxWidth="sm" fullWidth open={isModalOpen} onClose={closeModal}>
        <DialogTitle>
          <Typography variant="h3" component="h2" mb={2}>
            Shortcuts
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Short Cut</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {NAV_SHORTCUTS.map((shortcut) => (
                <TableRow
                  key={shortcut.key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th">{`CTRL/CMD + SHIFT + ${shortcut.key.toUpperCase()}`}</TableCell>
                  <TableCell>{shortcut.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" sx={{ px: 8, m: 2 }} onClick={closeModal}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ShortcutOptions;
