import React, { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import EnumAutocomplete from 'src/components/FormInputs/BrandAutocomplete/EnumAutoComplete';
import {
  Box,
  alpha,
  IconButton,
  Tooltip,
  styled,
  Drawer,
  Button,
  TextField,
  InputBase
} from '@mui/material';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import useQueryParams from 'src/hooks/useQueryParams';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.light, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '40ch'
    }
  }
}));

function index() {
  const navigate = useNavigate();
  const [params] = useQueryParams();
  const [number, setNumber] = useState('');

  const handelSubmit = (e) => {
    e.preventDefault();

    if (!number) {
      return toast.error(`Enter a number`);
    }
    return navigate(`/sim-details?number=${number}`);
  };

  useEffect(() => {
    setNumber(params?.number || '');
  }, [params]);
  return (
    <form onSubmit={handelSubmit}>
      <Box display="flex" alignItems="center">
        <Search>
          <SearchIconWrapper>
            <SearchIcon color="primary" />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search by ICCID,IMSI,MSISDN "
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            type="text"
            inputProps={{ 'aria-label': 'search' }}
          />
        </Search>
        <Button
          variant="contained"
          sx={{
            borderTopLeftRadius: 2,
            borderBottomLeftRadius: 2,
            maxHeight: '5.5vh'
          }}
          type="submit"
        >
          Search
        </Button>
      </Box>
    </form>
  );
}

export default index;
